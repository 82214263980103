import { FC } from 'react';

import { SkillsHeader } from './components/SkillsHeader.page';
import { SkillsSection } from './components/SkillsSection.page';
import { SkillsExperience } from './components/SkillsExperience.page';

import 'styles/pages/Skills.styles.scss';
import { SkillsReference } from './components/SkillsReference.page';
import { SKillsProps } from './types/Skills.types';

export const Skills: FC<SKillsProps> = ({ deviceType, lang }) => {
    return (
        <section className='skillsSection'>
            <SkillsHeader lang={lang} />
            <SkillsSection deviceType={deviceType} lang={lang} />
            <SkillsExperience deviceType={deviceType} lang={lang} />
            <SkillsReference lang={lang} />
        </section>
    );
};
