import { FC } from 'react';

import { ButtonProps } from './types/Button.types';
import 'styles/pages/Button.styles.scss';
import { Link } from 'react-router-dom';

export const Button: FC<ButtonProps> = ({
    content,
    colorTheme,
    arrowColorTheme,
    containerColorTheme,
    linkToPage,
    itemData
}) => {
    return (
        <Link
            to={linkToPage}
            state={{ projectData: itemData }}
            className={`btnContainer ${containerColorTheme}`}>
            <p className={`contentStyle ${colorTheme}`}>{content}</p>
            <i className={arrowColorTheme} />
        </Link>
    );
};
