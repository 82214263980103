import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import 'styles/pages/ChangeLangBtn.styles.scss';

interface Props {
    onClick: () => void;
}

export const ChangeLangBtn: FC<Props> = ({ onClick }) => {
    const { i18n } = useTranslation();

    const onClickLanguageChange = (e: any) => {
        console.log(e.target.value);
        const language = e.target.value;
        i18n.changeLanguage(language);
        onClick();
    };

    return (
        <div className='customSelect'>
            <select
                className='dropdownLang'
                onChange={onClickLanguageChange}
                id='selectLanguage'>
                <option className='labelDropdown' value='en'>
                    EN
                </option>
                <option className='labelDropdown' value='nl'>
                    NL
                </option>
            </select>
        </div>
    );
};
