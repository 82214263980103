import React, { useEffect, useState } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Contact, Home, Projects, Skills } from 'components';
import { UseScreenSize } from 'hooks';
import { GetAllProjectsService } from 'services';
import { Navigation, Footer, LoaderSpinner, DetailCard, NotFound } from 'shared';
import 'hooks/translation/i18n';

function App() {
    const screenSize = UseScreenSize();
    const location = useLocation();
    const { i18n } = useTranslation();

    const [deviceType, setDeviceType] = useState('none');
    const [allProjects, setAllProjects] = useState<Array<any>>([]);

    const [loading, setIsLoading] = useState(true);

    useEffect(() => {
        GetAllProjectsService.getProjects().then((data) => {
            setAllProjects(data);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (screenSize.width < 940) {
            if (screenSize.width < 768) {
                setDeviceType('mobile');
            } else {
                setDeviceType('ipad');
            }
        } else {
            setDeviceType('desktop');
        }
    }, [screenSize, location]);

    return (
        <div className='app'>
            {loading ? (
                <LoaderSpinner lang={i18n} />
            ) : (
                <>
                    <Navigation deviceType={deviceType} lang={i18n} />
                    <Routes>
                        <Route path='/' element={<Home data={allProjects} lang={i18n} />} />
                        <Route
                            path='/projects'
                            element={<Projects data={allProjects} lang={i18n} />}
                        />
                        <Route
                            path='/projects/:projectTitle'
                            element={<DetailCard lang={i18n} />}
                        />
                        <Route
                            path='/skills'
                            element={<Skills deviceType={deviceType} lang={i18n} />}
                        />
                        <Route
                            path='/contact'
                            element={<Contact lang={i18n}/>}
                        />
                        <Route path='/*' element={<NotFound lang={i18n} />} />
                    </Routes>
                    <Footer lang={i18n}/>
                </>
            )}
        </ div>
    );
}

export default App;
