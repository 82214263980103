import { FC } from 'react';
import { Button } from 'shared';
import { HomeProps } from '../types/Home.types';

export const ServiceHomeSection: FC<HomeProps> = ({ lang }) => {
    return (
        <section className='serviceHomeSection'>
            <div className='titleServiceContainer'>
                <h2 className='titleService'>{lang.t('home.service.title')}</h2>
                <h2 className='subtitleService'>
                    {lang.t('home.service.subtitle')}
                </h2>
            </div>
            <div className='serviceContentContainer'>
                <div className='serviceContentItem uiDesigner'>
                    <h3 className='titleServiceItem'>UI Designer</h3>
                    <p className='descriptionServiceItem'>
                        {lang.t('home.service.ui.text')}
                    </p>
                    <div className='underlineServiceItem' />
                </div>
                <div className='serviceContentItem frontendDevelopment'>
                    <h3 className='titleServiceItem'>React Developer</h3>
                    <p className='descriptionServiceItem'>{lang.t('home.service.react.text')}</p>
                    <div className='underlineServiceItem' />
                </div>
                <div className='serviceContentItem graphicDesigner'>
                    <h3 className='titleServiceItem'>
                        {lang.t('home.service.graphic')}
                    </h3>
                    <p className='DescriptionServiceItem'>{lang.t('home.service.graphic.text')}</p>
                </div>
            </div>
        </section>
    );
};
