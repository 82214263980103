import { FC } from 'react';

import { Button } from 'shared';
import { ProjectCardProps } from '../types/ProjectCard.types';

export const ProjectCard: FC<ProjectCardProps> = ({
    itemData,
    lang,
    content,
    colorTheme,
    arrowColorTheme,
    containerColorTheme,
}) => {
    const showreal = itemData.images_showreals[0];

    return (
        <>
            <div className='projectContainer'>
                <div className='leftProject'>
                    <h2 className='titleProject'>{itemData.title}</h2>
                    <div className='descriptionProject'>
                        {lang.language === 'en'
                            ? itemData.description_en[0].descriptionEn > 75
                                ? `${itemData.description_en[0].descriptionEn.substring(0, 75)}...`
                                : itemData.description_en[0].descriptionEn
                            : itemData.description_nl[0].descriptionNl > 75
                                ? `${itemData.description_nl[0].descriptionNl.substring(0, 75)}...`
                                : itemData.description_nl[0].descriptionNl}
                    </div>
                    <div className='categoryProjectContainer'>
                        {lang.language === 'en'
                            ? itemData.categories_en.map(
                                (cat: any, index: number) => {
                                    return (
                                        <div
                                            className='categoryProjectContent'
                                            key={index}>
                                            {cat.categoriesEn}
                                        </div>
                                    );
                                },
                            )
                            : itemData.categories_nl.map(
                                (cat: any, index: number) => {
                                    return (
                                        <div
                                            className='categoryProjectContent'
                                            key={index}>
                                            {cat.categoriesNl}
                                        </div>
                                    );
                                },
                            )}
                    </div>
                    <Button
                        content={content}
                        colorTheme={colorTheme}
                        arrowColorTheme={arrowColorTheme}
                        containerColorTheme={containerColorTheme}
                        itemData={itemData}
                        linkToPage={`/projects/${itemData.title}`}
                    />
                </div>
                <div className='rightProject'>
                    <img
                        src={`https://drive.google.com/thumbnail?id=${showreal.filename}&sz=w1000`}
                        alt={showreal.fieldname}
                    />
                </div>
            </div>
            <div className='underlineContainer'>
                <div className='underlineProject'></div>
            </div>
        </>
    );
};
