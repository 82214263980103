import { FC } from 'react';

import { Link } from 'react-router-dom';
import { FaLinkedinIn, FaGithub, FaEnvelope } from 'react-icons/fa';

import { ContactProps } from './types/Contact.types';
import 'styles/pages/Contact.styles.scss';

export const Contact: FC<ContactProps> = ({ lang }) => {
    return (
        <section className='contactSection'>
            <div className='contactLeftSide'>
                <h1 className='contactTitle'>
                    {lang.t('contact.title')} <br />{' '}
                    <span>{lang.t('contact.title.span')}</span>
                </h1>
                <div className='klaarVoorSamenwerking'>
                    <p className='titleSamenwerking'>
                        {lang.t('contact.collaboration')}
                    </p>
                    <p className='subSamenwerking'>
                        {lang.t('contact.collaboration.sub')}
                    </p>
                </div>
                <div className='socialLinksHeader'>
                    <Link
                        to='https://www.linkedin.com/in/hannelore-maes-a308791b9/'
                        target='_blank'
                        rel='noreferrer'
                        className='socialLinkItem'>
                        <FaLinkedinIn size={20} />
                    </Link>
                    <Link
                        to='https://github.com/HanneloreMaes'
                        target='_blank'
                        rel='noreferrer'
                        className='socialLinkItem'>
                        <FaGithub size={20} />
                    </Link>
                    <Link
                        to='mailto:hannelore-maes@outlook.be'
                        className='socialLinkItem'>
                        <FaEnvelope size={20} />
                    </Link>
                </div>
            </div>
            <div className='contactRightSide'>
                <img
                    className='imgHannelore'
                    src={require('../../assets/CV_IMG.png')}
                    alt={lang.t('contact.about.me.alt')}
                />
            </div>
            <div className='meshGradient'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    );
};
