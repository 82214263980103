import { FC } from 'react';

import { Link } from 'react-router-dom';
import { FaLinkedinIn, FaGithub, FaEnvelope } from 'react-icons/fa';

import { SKillsProps } from '../types/Skills.types';
import { Button } from 'shared';

export const SkillsHeader: FC<SKillsProps> = ({ lang }) => {
    return (
        <section className='headerSkillsSection'>
            <div className='headerSection'>
                <div className='titleContainer'>
                    <h1 className='titleHannelore'>Hannelore</h1>
                    <h1 className='titleMaes'>Maes</h1>
                </div>
                <div className='contentHeader'>
                    <div className='socialLinksHeader'>
                        <Link
                            to='https://www.linkedin.com/in/hannelore-maes-a308791b9/'
                            target='_blank'
                            rel='noreferrer'
                            className='socialLinkItem'>
                            <FaLinkedinIn size={20} />
                        </Link>
                        <Link
                            to='https://github.com/HanneloreMaes'
                            target='_blank'
                            rel='noreferrer'
                            className='socialLinkItem'>
                            <FaGithub size={20} />
                        </Link>
                        <Link
                            to='mailto:hannelore-maes@outlook.be'
                            className='socialLinkItem'>
                            <FaEnvelope size={20} />
                        </Link>
                    </div>
                    <div className='textRightHeader'>
                        <p className='textHeader'>
                            <span>{lang.t('skills.header.text.1')}</span>
                            <br />
                            <br />
                            {lang.t('skills.header.text.2.1')}
                            <br />
                            <br />
                            <span>
                                {lang.t('skills.header.text.2.2')}{' '}
                                <span className='contactBold'>
                                    {lang.t('skills.header.text.2.3')}
                                </span>
                                !
                            </span>
                        </p>
                        <Button
                            content={lang.t('button.content.contact')}
                            colorTheme='blue'
                            arrowColorTheme='arrowBlue'
                            containerColorTheme='containerBlue'
                            linkToPage='/contact'
                        />
                    </div>
                </div>
            </div>
            <div className='meshGradient'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    );
};
