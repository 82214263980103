import { FC } from 'react';

import 'styles/pages/Loader.styles.scss';
import { LoaderProps } from './types/Loader.types';

export const LoaderSpinner: FC<LoaderProps> = ({ lang }) => {
    return (
        <>
            <div className='loaderText'>
                <p className='dataText'>Ophalen van de projecten</p>
                <p className='minutesText'>Dit kan eventjes duren</p>
            </div>
            <div className='loader'>
                <div className='loader-inner'>
                    <div className='loader-line-wrap'>
                        <div className='loader-line'></div>
                    </div>
                    <div className='loader-line-wrap'>
                        <div className='loader-line'></div>
                    </div>
                    <div className='loader-line-wrap'>
                        <div className='loader-line'></div>
                    </div>
                    <div className='loader-line-wrap'>
                        <div className='loader-line'></div>
                    </div>
                    <div className='loader-line-wrap'>
                        <div className='loader-line'></div>
                    </div>
                </div>
            </div>
        </>
    );
};
