import { FC } from 'react';

import { ProjectCard } from 'shared';

import 'styles/pages/Projects.styles.scss';
import { ProjectsProps } from './types/Projects.types';

export const Projects: FC<ProjectsProps> = ({ data, lang }) => {
    return (
        <section className='projectsSection'>
            {data.map((item: any, index: number) => {
                return (
                    <ProjectCard
                        key={index}
                        lang={lang}
                        itemData={item}
                        content={lang.t('project.card.content.info')}
                        colorTheme='blue'
                        arrowColorTheme='arrowBlue'
                        containerColorTheme='containerBlue'
                    />
                );
            })}

            <div className='meshGradient'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div className='meshGradient meshGradientProjects'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div className='meshGradient meshGradientProjects2'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    );
};
