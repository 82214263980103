import { FC } from 'react';

import 'styles/pages/Home.styles.scss';
import { HeaderSection } from './components/Headersection.page';
import { ProjectSection } from './components/ProjectsSection.page';
import { ServiceHomeSection } from './components/ServiceSection.page';
import { HomeProps } from './types/Home.types';

export const Home: FC<HomeProps> = ({ data, lang }) => {
    return (
        <section className='homeSection'>
            <HeaderSection lang={lang} />
            <ProjectSection data={data} lang={lang}/>
            <ServiceHomeSection lang={lang}/>
        </section>
    );
};
