import { FC, useEffect, useRef, useState } from 'react';

import { FaReact, FaWordpressSimple, FaFigma } from 'react-icons/fa';
import { BiLogoTypescript } from 'react-icons/bi';
import {
    SiAdobephotoshop,
    SiAdobeillustrator,
    SiAdobeindesign,
    SiAdobexd,
} from 'react-icons/si';
import { SKillsProps } from '../types/Skills.types';

export const SkillsSection: FC<SKillsProps> = ({ deviceType }) => {
    const [sizeIcon, setSizeIcon] = useState(0);

    useEffect(() => {
        if (deviceType === 'mobile') {
            setSizeIcon(45);
        } else if (deviceType === 'desktop') {
            setSizeIcon(70);
        }
    }, [deviceType]);

    return (
        <section className='projectsHomeSection'>
            <div className='backgroundTop' />
            <div className='contentProjectInfo skillsProjectInfo'>
                <div className='subtitleContainer'>
                    <p className='subtitleContent'>My Skills</p>
                </div>
                <div className='skilssContentContainer'>
                    <FaReact size={sizeIcon} className='iconSkills' />
                    <BiLogoTypescript size={sizeIcon} className='iconSkills' />
                    <FaWordpressSimple size={sizeIcon} className='iconSkills' />
                    <FaFigma size={sizeIcon} className='iconSkills' />
                    <SiAdobephotoshop size={sizeIcon} className='iconSkills' />
                    <SiAdobeillustrator size={sizeIcon} className='iconSkills' />
                    <SiAdobeindesign size={sizeIcon} className='iconSkills' />
                    <SiAdobexd size={sizeIcon} className='iconSkills' />
                </div>
            </div>
            <div className='backgroundBottom backgroundBottomSkills' />
        </section>
    );
};
