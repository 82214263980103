import { FC } from 'react';
import { SKillsProps } from '../types/Skills.types';

export const SkillsReference: FC<SKillsProps> = ({ lang }) => {
    return (
        <section className='skillsReferenceSection'>
            <div className='titleServiceContainer'>
                <h2 className='subtitleExperience'>
                    {lang.t('skills.reference.title')}
                </h2>
            </div>
            <div className='experienceContentContainer'>
                <div className='experience-1'>
                    <div className='titleSectionExperience'>
                        <div className='yearExperienceContainer'>
                            <p className='yearExperience'>2024</p>
                        </div>
                        <div className='leftSectionExperience'>
                            <h3 className='titleExperience titleSectionReference'>
                                Mark Tollenaere
                            </h3>
                            <p className='functionExperience'>
                                Managing Director, Motionmill
                            </p>
                        </div>
                    </div>
                    <p className='textExperience'>
                        {lang.t('skills.reference.motionmill.text.1')}
                        <br />
                        <br />
                        <span className='spanReference'>
                            {lang.t('skills.reference.motionmill.span.1')}
                        </span>
                        <br />
                        {lang.t('skills.reference.motionmill.text.2')}
                        <br />
                        <br />
                        <span className='spanReference'>
                            {lang.t('skills.reference.motionmill.span.2')}
                        </span>
                        <br />
                        {lang.t('skills.reference.motionmill.text.3')}
                        <br />
                        <br />
                        <span className='spanReference'>
                            {lang.t('skills.reference.motionmill.span.3')}
                        </span>
                        <br />
                        {lang.t('skills.reference.motionmill.text.4')}
                        <br />
                        <br />
                        {lang.t('skills.reference.motionmill.text.5')}
                    </p>
                </div>
            </div>
        </section>
    );
};
