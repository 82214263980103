import { FC } from 'react';
import 'styles/pages/NotFound.styles.scss';

interface NotFoundProps {
    lang: any;
}

export const NotFound: FC<NotFoundProps> = ({lang}) => {
    return (
        <section className='error-container'>
            <div className='numbersError'>
                <span className='four'>
                    <span className='screen-reader-text'>4</span>
                </span>
                <span className='zero'>
                    <span className='screen-reader-text'>0</span>
                </span>
                <span className='four'>
                    <span className='screen-reader-text'>4</span>
                </span>
            </div>

            <p className='textNotFound'>{lang.t('notFound')}</p>
        </section>
    );
};
