import { FC } from 'react';
import { FaFigma, FaReact, FaWordpress } from 'react-icons/fa';
import {
    SiAdobeillustrator,
    SiAdobeindesign,
    SiAdobephotoshop,
    SiAdobexd,
} from 'react-icons/si';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { DetailCardProps } from './types/DetailProjectCard.types';

import 'styles/pages/projects/DetailCard.styles.scss';

export const DetailCard: FC<DetailCardProps> = ({ lang }) => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const detailData = state.projectData;

    const headImg = detailData.images_showreals[0];
    const allImages = detailData.images_projects;

    const date = detailData.dateOfRelease;
    const yearOfDate = new Date(date).getFullYear();

    return (
        <section className='detailCardContainer'>
            <div className='goBackContainer' onClick={() => navigate(-1)}>
                <i className='arrowBackIcon' />
                <span>{lang.t('detailCard.back')}</span>
            </div>
            <div className='topDetailCard'>
                <h1 className='titleDetailCard'>{detailData.title}</h1>
                <div className='categoriesDetailCardContainer'>
                    {lang.language === 'en'
                        ? detailData.categories_en.map((cat: any, index: number) => {
                            return (
                                <div className='catDetailItem' key={index}>
                                    <p className='catDetailCard'>
                                        {cat.categoriesEn}
                                    </p>
                                </div>
                            );
                        })
                        : detailData.categories_nl.map((cat: any, index: number) => {
                            return (
                                <div className='catDetailItem' key={index}>
                                    <p className='catDetailCard'>
                                        {cat.categoriesNl}
                                    </p>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className='imgDetailCardContainer'>
                <img
                    // eslint-disable-next-line max-len
                    src={`https://drive.google.com/thumbnail?id=${headImg.filename}&sz=w1000`}
                    alt={headImg.fieldname}
                    className='imgDetailCard'
                />
            </div>
            <div className='infoDetailCardContainer'>
                <div className='infoDetailLeft'>
                    <div className='yearDetail detailInfoContainer'>
                        <p className='yearDetailCard detailTag'>
                            {lang.t('detailCard.year')}
                        </p>
                        <p className='yearDetailCard detailInfo'>{yearOfDate}</p>
                    </div>
                    <div className='descriptionDetail detailInfoContainer'>
                        <p className='descriptionDetailCardTag detailTag'>
                            {lang.t('detailCard.description')}
                        </p>
                        {lang.language === 'en'
                            ? detailData.description_en.map(
                                (description: any, index: any) => (
                                    <p
                                        className='descriptionDetailCard detailInfo'
                                        key={index}>
                                        {description.descriptionEn}
                                    </p>
                                ),
                            )
                            : detailData.description_nl.map(
                                (description: any, index: any) => (
                                    <p
                                        className='descriptionDetailCard detailInfo'
                                        key={index}>
                                        {description.descriptionNl}
                                    </p>
                                ),
                            )}
                    </div>
                </div>
                <div className='infoDetailRight'>
                    {detailData.client !== 'Hannelore Maes' ? (
                        <div className='detailInfoContainerDesktop'>
                            <p className='detailTag'>
                                {lang.t('detailCard.client')}
                            </p>
                            <p className='clientNameText detailInfo'>
                                {detailData.client}
                            </p>
                        </div>
                    ) : null}
                    {detailData.link !== 'no-link' ? (
                        <div className='detailInfoContainerDesktop'>
                            <p className='detailTag'>{lang.t('detailCard.link')}</p>
                            <Link
                                to={detailData.link}
                                target='_blank'
                                rel='noreferrer'
                                className='linkedInContact portfolioLinkBlack detailInfo'>
                                {detailData.link}
                            </Link>
                        </div>
                    ) : null}
                    <div className='typeOfDetailCardTag'>
                        <p className='detailTag'>{lang.t('detailCard.software')}</p>
                        <div className='typesDetailCards'>
                            {detailData.type_of_application.map(
                                (typeOfApp: any, index: number) => {
                                    const typeOf = typeOfApp.typeOfApplication;
                                    return (
                                        <div
                                            className='typeIconsContainer'
                                            key={index}>
                                            {typeOf === 'InDesign' ? (
                                                <SiAdobeindesign fontSize={40} />
                                            ) : null}
                                            {typeOf === 'Illustrator' ? (
                                                <SiAdobeillustrator fontSize={40} />
                                            ) : null}
                                            {typeOf === 'XD' ? (
                                                <SiAdobexd fontSize={40} />
                                            ) : null}
                                            {typeOf === 'Photoshop' ? (
                                                <SiAdobephotoshop fontSize={40} />
                                            ) : null}
                                            {typeOf === 'Figma' ? (
                                                <FaFigma fontSize={40} />
                                            ) : null}
                                            {typeOf === 'React' ? (
                                                <FaReact fontSize={40} />
                                            ) : null}
                                            {typeOf === 'React Native' ? (
                                                <FaReact fontSize={40} />
                                            ) : null}
                                            {typeOf === 'Wordpress' ? (
                                                <FaWordpress fontSize={40} />
                                            ) : null}
                                        </div>
                                    );
                                },
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='imgTotalDetailCardContainer'>
                {detailData.images_projects.length > 1
                    ? allImages.map((img: any, index: number) => {
                        return (
                            <img
                                key={index}
                                // eslint-disable-next-line max-len
                                src={`https://drive.google.com/thumbnail?id=${img.filename}&sz=w1000`}
                                alt={img.fieldname}
                                className='imgDetailCard detailImageTotal'
                            />
                        );
                    })
                    : null}
            </div>
        </section>
    );
};
