import { FC, useEffect, useState } from 'react';

import { Button, ProjectCard } from 'shared';
import { HomeProps } from '../types/Home.types';

export const ProjectSection: FC<HomeProps> = ({ data, lang }) => {
    const [highlighted, setHighlighted] = useState<any>([]);

    const filteredData = () => {
        const highlighProject = data.sort(function (a: any, b: any) {
            return b.highlighted - a.highlighted;
        });

        setHighlighted(highlighProject);
    };

    useEffect(() => {
        filteredData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <section className='projectsHomeSection'>
            <div className='backgroundTop' />
            <div className='contentProjectInfo'>
                <div className='subtitleContainer'>
                    <p className='subtitleContent'>{lang.t('home.projects.title')}</p>
                </div>
                <div className='projectsHomeContainer'>
                    {highlighted !== null || undefined
                        ? highlighted.map((project: any, index: any) => {
                            if (project.highlighted) {
                                return (
                                    <ProjectCard
                                        key={index}
                                        itemData={project}
                                        lang={lang}
                                        content={lang.t('project.card.content.info')}
                                        colorTheme='white'
                                        arrowColorTheme='arrowWhite'
                                        containerColorTheme='containerWhite'
                                    />
                                );
                            } else {
                                null;
                            }
                        })
                        : null}
                </div>
            </div>
            <div className='backgroundBottom' />
        </section>
    );
};
