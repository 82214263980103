import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import English from './English/English.json';
import Dutch from './Dutch/Dutch.json';

const resources = {
    en: {
        translation: English,
    },
    nl: {
        translation: Dutch,
    },
};

i18next.use(initReactI18next).init({
    resources,
    lng: 'en',
});

export default i18next;
