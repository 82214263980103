class GetProjectsService {
    private readonly url = 'https://portfolio-api.hanneloremaes.be/admin_api_backend/projects';

    async getProjects() {
        const resp = await fetch(`${this.url}`, {
            method: 'GET',
        });
        return resp.json();
    }
}

export const GetAllProjectsService = new GetProjectsService();