import React, { FC, useEffect, useState } from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import { NavBarProps } from './types/Navigation.types';
import 'styles/pages/Navigation.styles.scss';
import { ChangeLangBtn } from 'shared';

export const Navigation: FC<NavBarProps> = ({ deviceType, lang }) => {
    const location = useLocation();

    const [showMobileNav, setShowMobileNav] = useState(false);

    const handleShowNavbar = () => {
        setShowMobileNav(!showMobileNav);
    };

    return (
        <nav className='fixedNavigation'>
            <div className='navContainer'>
                <NavLink to='/' className='logo'>
                    <p className='logoOfSite'>Hannelore Maes</p>
                </NavLink>
                <div
                    className={
                        showMobileNav === false ? 'hamburger' : 'hamburger open'
                    }
                    onClick={handleShowNavbar}>
                    <div className='line-menu half start' />
                    <div className='line-menu' />
                    <div className='line-menu half end' />
                </div>
                <div className={`navItems  ${showMobileNav && 'active'}`}>
                    <ul>
                        <li className='navItem'>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive
                                        ? 'linkNav activeLink portfolioLinkActive'
                                        : 'linkNav portfolioLink'
                                }
                                to='/'
                                onClick={handleShowNavbar}>
                                Home
                            </NavLink>
                        </li>
                        <li className='navItem'>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive
                                        ? 'linkNav activeLink portfolioLinkActive'
                                        : 'linkNav portfolioLink'
                                }
                                to='/projects'
                                onClick={handleShowNavbar}>
                                {lang.language === 'en' ? 'Projects' : 'Projecten'}
                            </NavLink>
                        </li>
                        <li className='navItem'>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive
                                        ? 'linkNav activeLink portfolioLinkActive'
                                        : 'linkNav portfolioLink'
                                }
                                to='/skills'
                                onClick={handleShowNavbar}>
                                {lang.language === 'en' ? 'Skills' : 'Vaardigheden'}
                            </NavLink>
                        </li>
                        <li className='navItem'>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive
                                        ? 'linkNav activeLink portfolioLinkActive'
                                        : 'linkNav portfolioLink'
                                }
                                to='/contact'
                                onClick={handleShowNavbar}>
                                Contact
                            </NavLink>
                        </li>
                        <ChangeLangBtn onClick={handleShowNavbar} />
                    </ul>
                </div>
            </div>
            <div
                className={`greyOverlay ${showMobileNav && 'activeOverlay'}`}
                onClick={handleShowNavbar}
            />
        </nav>
    );
};
