import { FC } from 'react';
import { SKillsProps } from '../types/Skills.types';

export const SkillsExperience: FC<SKillsProps> = ({ deviceType, lang }) => {
    return (
        <section className='skillsExperienceSection'>
            <div className='titleServiceContainer'>
                <h2 className='subtitleExperience'>
                    {lang.t('skills.experience.title')}
                </h2>
            </div>
            <div className='experienceContentContainer'>
                <div className='experience-1'>
                    <div className='titleSectionExperience'>
                        <div className='yearExperienceContainer'>
                            <p className='yearExperience'>2024</p>
                        </div>
                        <div className='leftSectionExperience'>
                            <h3 className='titleExperience'>
                                {lang.t('home.service.graphic')}
                            </h3>
                            <p className='functionExperience'>
                                {lang.t('skills.experience.stage')}, Motionmill
                            </p>
                        </div>
                    </div>
                    <p className='textExperience'>
                        {lang.t('skills.experience.motionmill.text.1')}
                        <br /><br />
                        {lang.t('skills.experience.motionmill.text.2')}
                    </p>
                </div>
                {deviceType == ('mobile' || 'ipad') ? (
                    <div className='underlineContainer'>
                        <div className='underlineProject'></div>
                    </div>
                ) : (
                    <div className='underlineSkillsContainer' />
                )}
                <div className='experience-2'>
                    <div className='titleSectionExperience'>
                        <div className='yearExperienceContainer'>
                            <p className='yearExperience'>2023</p>
                        </div>
                        <div className='leftSectionExperience'>
                            <h3 className='titleExperience'>
                                {lang.t('skills.experience.mobile')}
                            </h3>
                            <p className='functionExperience'>
                                {lang.t('skills.experience.stage')}, Studio
                                Hyperdrive
                            </p>
                        </div>
                    </div>
                    <p className='textExperience'>
                        {lang.t('skills.experience.hyperdrive.text.1')}
                        <br /><br />
                        {lang.t('skills.experience.hyperdrive.text.2')}
                    </p>
                </div>
            </div>
        </section>
    );
};
