import { FC } from 'react';

import { Link } from 'react-router-dom';

import { FooterProps } from './types/Footer.types';
import 'styles/pages/Footer.styles.scss';

export const Footer: FC<FooterProps> = ({ lang }) => {
    return (
        <footer className='footerContainer'>
            <div className='footerPadding'>
                <h1 className='titleTopFooter'>{lang.t('footer.working.text.1')}</h1>
                <h1 className='titleBottomFooter'>{lang.t('footer.working.text.2')}</h1>

                <div className='footerContent'>
                    <p className='copyright'>©2024 | Hannelore Maes</p>
                    <div className='linkContainer'>
                        <Link
                            className='socialLink'
                            to='mailto:hannelore-maes@outlook.be'>
                            Hannelore-Maes@outlook.be
                        </Link>
                        <Link
                            className='socialLink'
                            to='https://www.linkedin.com/in/hannelore-maes-a308791b9/'
                            target='_blank'
                            rel='noreferrer'>
                            LinkedIn
                        </Link>
                        <Link
                            className='socialLink'
                            to='https://github.com/HanneloreMaes'
                            target='_blank'
                            rel='noreferrer'>
                            Github
                        </Link>
                    </div>
                </div>
            </div>
            <p className='footerName'>HANNELORE MAES</p>
        </footer>
    );
};
